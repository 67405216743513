
import { computed, defineComponent, ref } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import { useModals } from '@/compositions/modals'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'
import { useRoute } from 'vue-router'
import { useBreakpoints } from '@/compositions/breakpoints'
import type { User } from '@/definitions/_general/_types/types'

const deactivatedUser: User = {
  person: {
    uid: '1224ddc9-27aa-454d-a6e0-cbbebefaa156',
    firstName: 'Ruth',
    lastName: 'Adkins',
    fullName: 'Ruth Adkins',
    avatar: 'https://randomuser.me/api/portraits/women/15.jpg',
    avatarColor: 'green',
  },
  email: 'ruth.adkins@gmail.com',
  userRole: 'Admin',
  userTeams: ['Sales'],
  userStatus: {
    name: 'active',
    color: 'green',
    outline: true,
  },
  assignedItems: [
    {
      icon: 'chat',
      name: '6 chats',
      link: {
        // name: 'user.messenger',
        name: 'base',
      },
    },
    {
      icon: 'email',
      name: '45 tickets',
      link: {
        // name: 'user.tickets',
        name: 'base',
      },
    },
    {
      icon: 'person',
      name: '24 contacts',
      link: {
        // name: 'user.contacts',
        name: 'base',
      },
    },
    {
      icon: 'business',
      name: '4 organizations',
      link: {
        // name: 'user.contacts.organizations',
        name: 'base',
      },
    },
  ],
  assignItemsTo: {
    fullName: 'Colleen Richards',
  },
  job: 'Dynamic Infrastructure Developer',
  lastActive: '2023-02-06T20:31:35.074Z',
  userFa: true,
}

export default defineComponent({
  name: 'UserDetails',
  components: {
    TmAutoSizePanelItemButton,
    PageBlock,
    DetailsHero,
  },
  setup() {
    const route = useRoute()
    const { openModal } = useModals()
    const breadcrumbs = [
      { label: 'Users', url: { name: 'base.admin.users' } },
      { label: 'Ruth Adkins', url: '' },
    ]
    const currentRoute = ref('')
    const status = computed(() => route.query?.status || 'active')
    const tabsRouter = ref([
      {
        name: 'Profile',
        to: `profile?status=${status.value}`,
      },
      {
        name: 'Audit logs',
        to: `audit-logs?status=${status.value}`,
      },
      {
        name: 'Outbound SMS',
        to: `outbound-sms?status=${status.value}`,
      },
      {
        name: 'Inbound SMS',
        to: `inbound-sms?status=${status.value}`,
      },
    ])

    const { isXsMax } = useBreakpoints()
    const { openUserResetPassModal, openCancelInvitationModal, openResendInvitationModal, openUserLogOutModal, openActivateUserModal } = useAccountOpenModal()

    const hero = ref({
      person: {
        firstName: 'Ruth',
        lastName: 'Adkins',
        avatar: status.value !== 'invited' ? 'https://randomuser.me/api/portraits/women/15.jpg' : '',
        avatarColor: 'green',
      },
      infos: [
        { label: 'Agent', showFull: true },
        { label: 'ruth.agent@textmagic.com' },
      ],
    })

    // const heroFullName = computed(() => `${hero.value.person.firstName} ${hero.value.person.lastName}`)
    const heroTitle = computed(() => {
      return status.value === 'invited'
        ? hero.value.infos[1].label
        : `${hero.value.person.firstName} ${hero.value.person.lastName}`
    })

    const btns = computed(() => {
      const arr: any = []
      const btnsObj: any = {
        reactivate: {
          icon: 'refresh',
          isShown: status.value === 'deactivated',
          label: 'Activate',
          eventClick: () => openActivateUserModal(heroTitle.value),
        },
        resetPassword: {
          icon: 'email',
          isShown: status.value === 'active',
          label: 'Reset password',
          eventClick: () => openUserResetPassModal(hero.value.infos[1].label),
        },
        edit: {
          icon: 'edit',
          isShown: true,
          label: 'Edit',
          eventClick: () => openEditUserModal(),
        },
      }

      Object.keys(btnsObj).forEach(btn => {
        if (btnsObj[btn].isShown) arr.push(btnsObj[btn])
      })

      return arr
    })

    const openEditUserModal = () => openModal('workspaceEditUser')
    const openDeactivateUserModal = () => openModal('workspaceDeactivateUser', { user: deactivatedUser })

    return {
      breadcrumbs,
      currentRoute,
      tabsRouter,
      isXsMax,
      hero,
      openEditUserModal,
      openDeactivateUserModal,
      openActivateUserModal,
      openCancelInvitationModal,
      openResendInvitationModal,
      openUserLogOutModal,
      openUserResetPassModal,
      status,
      heroTitle,
      openModal,
      btns,
      deactivatedUser,
    }
  },
})
