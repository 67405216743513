import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_block = _resolveComponent("page-block")!

  return (_openBlock(), _createBlock(_component_page_block, { breadcrumbs: _ctx.breadcrumbs }, {
    "page-blocks-top": _withCtx(() => [
      _createVNode(_component_details_hero, {
        pattern: "green",
        class: "mb-5",
        title: _ctx.heroTitle,
        "avatar-url": _ctx.hero.person.avatar,
        infos: _ctx.hero.infos,
        "avatar-color": _ctx.hero.person.avatarColor,
        "avatar-editable": _ctx.status !== 'invited',
        tabs: _ctx.tabsRouter,
        ellipsis: ""
      }, {
        right: _withCtx(() => [
          (_ctx.status === 'active')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "tmi-login",
                  text: "Reset password",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUserResetPassModal(_ctx.hero.infos[1].label)))
                }),
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "edit",
                  text: "Edit",
                  onClick: _ctx.openEditUserModal
                }, null, 8, ["onClick"]),
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "tmi-logout-exit",
                  text: "Log out from all devices",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openUserLogOutModal(_ctx.heroTitle)))
                }),
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "close",
                  text: "Deactivate user",
                  onClick: _ctx.openDeactivateUserModal
                }, null, 8, ["onClick"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.status === 'invited')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "send",
                  text: "Resend invitation",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openResendInvitationModal(_ctx.heroTitle)))
                }),
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "close",
                  text: "Cancel invitation",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openCancelInvitationModal(_ctx.heroTitle)))
                })
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.status === 'deactivated')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "refresh",
                  text: "Activate",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openActivateUserModal(_ctx.heroTitle)))
                }),
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "edit",
                  text: "Edit",
                  onClick: _ctx.openEditUserModal
                }, null, 8, ["onClick"])
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "avatar-url", "infos", "avatar-color", "avatar-editable", "tabs"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }, 8, ["breadcrumbs"]))
}